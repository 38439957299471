@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary: #2d2d2d;
  --font-size: 20px;
  --background-one: #b0e6ff;
  --xs: 375px;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: var(--font-size);
  color: var(--primary);
  background-color: var(--background-one);
}

button {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--primary);
}

#root,
body,
html {
  height: 100%;
}

.awssld__content > div {
  display: contents;
}

.awssld__content {
  overflow-y: auto !important;
}
